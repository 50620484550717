import React from "react";
import MainPage from "./MainPage";
import './index.css'

function App() {
  return (
    <>
      <div className="bg-[#f6f3e7]">
        <MainPage/>
      </div>
    </>
  );
}

export default App;
