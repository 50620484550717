import React, { forwardRef } from 'react'
import Links from '../components/links'

const Section4 = forwardRef((props, ref) => {
    return (
        <section ref={ref} className="py-6 dark:bg-[#48b4a0] dark:text-[#f6f3e7]">
            <div className="grid max-w-6xl grid-cols-1 px-6 mx-auto lg:px-8 md:grid-cols-2 md:divide-x">
                <div className="py-6 md:py-0 md:px-6">
                    <h1 className="text-4xl font-bold">Contact</h1>
                    <p className="pt-2 pb-4">Email me if you wanna have a chat!</p>
                    <div className="space-y-4">
                        <p className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                            </svg>
                            <span>Kansas, USA</span>
                        </p>
                        <p className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-2 sm:mr-6">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                            <span>kle2@nd.edu</span>
                        </p>
                    </div>
                </div>
                <div className="flex flex-col py-6 space-y-6 md:py-0 md:px-6">
                    <Links/>
                </div>
            </div>
        </section>
    )
})

export default Section4